*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Next block defines what root 1rem is */

/* Standard font-size is 16px.  If 16px is 100%, then 10px is 62.5% */
/* Here 1rem = 10px */

html {
  font-size: 62.5%;
}

/* screen size: >= 1800px; html font-size: 12px */
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }  
}

/* screen size: <= 1200px; html font-size: 9px */
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }  
}

/* screen size: <= 900px; html font-size: 8px */
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }  
}

/* screen size: <= 600px; html font-size: 7px */
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 43.75%;
  }  
}

body {
  box-sizing: border-box;
  font-size: 1.6rem;
  /* font-family: "Lato", sans-serif; */
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-family: 'Roboto', 'Noto Sans JP', sans-serif;
  /* font-family: Helvetica; */
  font-weight: 400;
  line-height: 1.7;
  color: white;
}

::selection {
  background-color: #55c57a;
  color: #fff;
}